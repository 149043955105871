import reports from "./reports.js";

let questions = require("./qs.js"),
	qs = questions.qs,
	menuItems = require("./ms.js"),
	ms = menuItems.ms,
	hr = "hr";

ms = { ...ms, ...qs };

let surveySpecs = {
	RY: [
		{
			name: "Student Resilience",
			group: "student",
			permission: "surveyStudentResilience",
			id: "student-resilience",
			landingPage: [
				reports["mental-health"],
				reports["snapshot"],
				reports["executive-summary"],
				reports["comments"],
				reports["mindset"],
				// reports['gemfinder']
			],
			pdfMenu: [
				ms["pdf-mentalhealth"],
				hr,
				ms["pdf-resilience"],
				ms["pdf-healthy-mind"],
				ms["pdf-ready-to-learn"],
				hr,
				{
					label: "Connected Protected Respected",
					items: [
						ms["pdf-snapshot"],
						ms["pdf-cross-sectional"],
						ms["pdf-longitudinal"],
						ms["pdf-time-lag"],
						ms["executive-summary"],
					],
					open: true,
				},
				{
					...ms["pdf-demographics"],
					permission: "reportDemographic",
				}
			],
			interactiveMenu: [
				{
					label: "Mental Health",
					items: [
						ms["cantril-single"],
						{
							label: "Hope <strong>(CHS)</strong>",
							items: [
								ms.CHS,
								{
									label: "Agency Items",
									items: [ms.chs1, ms.chs3, ms.chs5],
								},
								{
									label: "Pathways Items",
									items: [ms.chs2, ms.chs4, ms.chs6],
								},
							],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Coping <strong>(CSI-4*)</strong>",
							items: [
								ms["CSI-4"],
								{
									label: "Items",
									items: [ms.cop1, ms.cop2, ms.cop3, ms.cop4],
								},
							],
						},
						{
							label: "Resilience Score",
							items: [
								ms["resilience"],
								{
									label: "Items",
									items: [
										{
											key: 'cantril',
											label: 'Cantril'
										},
										{
											key: 'CHS',
											label: 'CHS'
										},
									],
								},
							],
						},
						{
							label: "Healthy Mind Score",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Ready to Learn Score",
							items: [
								ms["ready-to-learn"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.cop1, ms.cop2, ms.cop3, ms.cop4],
								},
							],
						},
						ms["IINS-single"],
					],
					open: true,
				},
				hr,
				ms.snapshot,
				// {
				// 	label: 'Gemfinder',
				// 	items: [
				// 		{
				// 			key: 'gemfinder-walkthrough',
				// 			label: 'Walkthrough'
				// 		},
				// 		// {
				// 		// 	key: 'gemfinder',
				// 		// 	label: 'Sandbox'
				// 		// }
				// 	]
				// },
				ms.comments,
				hr,
				{
					label: "Connected",
					items: [
						{
							label: "Positive Relationships",
							items: [ms.ry2, ms.ry3, ms.ry4, ms.ry5, ms.ry6, ms.ry7, ms.ry27],
						},
						{
							label: "Social Skills",
							items: [ms.ry8, ms.ry9, ms.ry10, ms.ry20],
						},
						{
							label: "Understanding Self",
							items: [ms.ry11, ms.chs1, ms.chs2, ms.chs3],
						},
					],
				},
				{
					label: "Protected",
					items: [
						{
							label: "Safety",
							items: [
								ms.ry13,
								ms.ry14,
								ms.ry15,
								ms.ry30,
								ms.ry31,
								ms.ry37,
								ms.ry38,
								ms.ry39,
								ms.ry40,
							],
						},
						{
							label: "Healthy Body",
							items: [ms.sun3],
						},
						{
							label: "Healthy Mind",
							items: [
								ms.ph1,
								ms.ph2,
								ms.ph3,
								ms.ph4,
								ms.phq3,
								ms.phq4,
								ms.phq5,
								ms.phq7,
								ms.cop1,
								ms.cop2,
								ms.cop3,
								ms.cop4,
							],
						},
						{
							label: "Learning Engagement",
							items: [ms.ry16, ms.ry17, ms.ry18, ms.ry20, ms.ry28],
						},
					],
				},
				{
					label: "Respected",
					items: [
						{
							label: "Positive Attitude",
							items: [ms.ry21, ms.chs4, ms.chs5, ms.chs6],
						},
						{
							label: "Positive Values",
							items: [ms.k12, ms.for1],
						},
						{
							label: "Positive Contribution",
							items: [ms.ry22, ms.ry25, ms.ry26],
						},
					],
				},
				hr,
				{
					permission: "riskyBehaviours",
					label: "Risky Behaviours",
					items: [
						ms.ry32,
						ms.aud1,
						ms.aud2,
						ms.aud3,
						ms.gam1,
						ms.gam2,
						ms.ry42,
						ms.ry43,
					],
				},
			],
		},
		// {
		// 	name: "Short Resilience",
		// 	group: "student",
		// 	permission: "surveyStudentResilienceShort",
		// 	id: "short-resilience",
		// 	landingPage: [
		// 		reports["snapshot-short"],
		// 		reports["mindset-short"],
		// 		reports["executive-summary-short"],
		// 		reports["comments-short"],
		// 	],
		// 	pdfMenu: [
		// 		ms["pdf-snapshot-short"],
		// 		ms["pdf-ready-to-learn"],
		// 		{
		// 			label: "Executive Summary",
		// 			items: [
		// 				ms["executive-summary-short"],
		// 				ms["pdf-cross-sectional-short"],
		// 				ms["pdf-longitudinal-short"],
		// 				ms["pdf-time-lag-short"],
		// 			],
		// 			open: true,
		// 		},
		// 	],
		// 	interactiveMenu: [
		// 		{
		// 			label: "Mental Health",
		// 			items: [
		// 				ms["cantril-single"],
		// 				{
		// 					label: "Hope <strong>(CHS)</strong>",
		// 					items: [
		// 						ms.CHS,
		// 						{
		// 							label: "Agency Items",
		// 							items: [ms.chs1, ms.chs3, ms.chs5],
		// 						},
		// 						{
		// 							label: "Pathways Items",
		// 							items: [ms.chs2, ms.chs4, ms.chs6],
		// 						},
		// 					],
		// 				},
		// 				{
		// 					label: "Anxiety <strong>(GAD-2)</strong>",
		// 					items: [
		// 						ms["GAD-2"],
		// 						{
		// 							label: "Items",
		// 							items: [ms.ph1, ms.ph2],
		// 						},
		// 					],
		// 				},
		// 				{
		// 					label: "Depression <strong>(PHQ-2)</strong>",
		// 					items: [
		// 						ms["PHQ-2"],
		// 						{
		// 							label: "Items",
		// 							items: [ms.ph3, ms.ph4],
		// 						},
		// 					],
		// 				},
		// 				{
		// 					label: "Coping <strong>(CSI-4*)</strong>",
		// 					items: [
		// 						ms["CSI-4"],
		// 						{
		// 							label: "Items",
		// 							items: [ms.cop1, ms.cop2, ms.cop3, ms.cop4],
		// 						},
		// 					],
		// 				},
		// 				{
		// 					label: "Anxiety & Depression",
		// 					items: [
		// 						ms["resilience"],
		// 						{
		// 							label: "Items",
		// 							items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
		// 						},
		// 					],
		// 				},
		// 				{
		// 					label: "Positive Learners Mindset",
		// 					items: [
		// 						ms["ready-to-learn"],
		// 						{
		// 							label: "Items",
		// 							items: [ms.ph1, ms.ph2, ms.cop1, ms.cop2, ms.cop3, ms.cop4],
		// 						},
		// 					],
		// 				},
		// 				ms["IINS-single"],
		// 			],
		// 			open: true,
		// 		},
		// 		hr,
		// 		ms["snapshot-short"],
		// 		ms.comments,
		// 		hr,
		// 		{
		// 			label: "Connected",
		// 			items: [
		// 				{
		// 					label: "Positive Relationships",
		// 					items: [ms.ry2, ms.ry3, ms.ry4, ms.ry5, ms.ry6, ms.ry27],
		// 				},
		// 				{
		// 					label: "Social Skills",
		// 					items: [ms.ry9],
		// 				},
		// 				{
		// 					label: "Understanding Self",
		// 					items: [ms.chs1, ms.chs2, ms.chs3],
		// 				},
		// 			],
		// 		},
		// 		{
		// 			label: "Protected",
		// 			items: [
		// 				{
		// 					label: "Safety",
		// 					items: [
		// 						ms.ry13,
		// 						ms.ry14,
		// 						ms.ry30,
		// 						ms.ry31,
		// 						ms.ry37,
		// 						ms.ry38,
		// 						ms.ry39,
		// 					],
		// 				},
		// 				{
		// 					label: "Healthy Body",
		// 					items: [ms.ry35, ms.sun3, ms.ry36],
		// 				},
		// 				{
		// 					label: "Healthy Mind",
		// 					items: [
		// 						ms.ph1,
		// 						ms.ph2,
		// 						ms.ph3,
		// 						ms.ph4,
		// 						ms.phq3,
		// 						ms.phq4,
		// 						ms.cop1,
		// 						ms.cop2,
		// 						ms.cop3,
		// 						ms.cop4,
		// 					],
		// 				},
		// 				{
		// 					label: "Learning Engagement",
		// 					items: [ms.ry16, ms.ry17, ms.ry18, ms.ry19, ms.ry20, ms.ry28],
		// 				},
		// 			],
		// 		},
		// 		{
		// 			label: "Respected",
		// 			items: [
		// 				{
		// 					label: "Positive Attitude",
		// 					items: [ms.chs4, ms.chs5, ms.chs6],
		// 				},
		// 				{
		// 					label: "Positive Values",
		// 					items: [ms.for1, ms.for2],
		// 				},
		// 				{
		// 					label: "Positive Contribution",
		// 					items: [ms.ry25],
		// 				},
		// 			],
		// 		},
		// 		hr,
		// 		{
		// 			permission: "riskyBehaviours",
		// 			label: "Risky Behaviours",
		// 			items: [
		// 				ms.ry32,
		// 				ms.aud1,
		// 				ms.aud2,
		// 				ms.aud3,
		// 				ms.gam1,
		// 				ms.gam2,
		// 				ms.ry42,
		// 				ms.ry43,
		// 			],
		// 		},
		// 	],
		// },
		{
			name: "No Bullying",
			group: "student",
			permission: "surveyNoBullying",
			id: "no-bullying",
			landingPage: [reports["overview"], reports["comments-no-bullying"]],
			pdfMenu: [ms["pdf-overview"]],
			interactiveMenu: [
				{
					label: "Bullying Profile",
					items: [
						{
							label: "Types of Bullying",
							items: [ms.b1, ms.b2, ms.b3, ms.b4],
							open: true,
						},
						ms.b5,
						ms.b6,
						ms.b7,
						ms.b8,
					],
					open: true,
				},
				{
					label: "Student Opinion",
					items: [ms.b11, ms.b12, ms.b13],
				},
				{
					label: "Comments",
					items: [ms.b21, ms.b22, ms.b23],
				},
				hr,
				{
					label: "Online Behaviours",
					items: [ms.cyb1, ms.cyb4, ms.cyb8, ms.cyb9],
				},
				{
					label: "Supportive Relationships",
					items: [ms.ry2, ms.ry3, ms.ry6, ms.ry14],
				},
			],
		},
		{
			name: "School Staff Resilience",
			group: "staff",
			permission: "surveySchoolStaff",
			id: "school-staff-resilience",
			landingPage: [
				reports["snapshot-staff"],
				reports["executive-summary-staff"],
				reports["comments-staff"],
			],
			pdfMenu: [
				ms["pdf-snapshot"],
				{
					label: "Executive Summary",
					items: [
						ms["executive-summary"],
						ms["pdf-cross-sectional"],
						ms["pdf-time-lag"],
					],
					open: true,
				},
			],
			interactiveMenu: [
				{
					label: "Mental Health",
					items: [
						{
							label: "Life Satisfaction <strong>(Cantril*)</strong>",
							items: [ms.cantril1, ms.cantril2, ms.cantril3],
						},
						{
							label: "Hope <strong>(AHS*)</strong>",
							items: [
								ms.AHS,
								{
									label: "Items",
									items: [ms.ahs1, ms.ahs3, ms.ahs4, ms.ahs5],
								},
							],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Anxiety & Depression",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
					],
					open: true,
				},
				ms.snapshot,
				{
					label: "Comments",
					items: [ms.rn50, ms.rn51, ms.rn52, ms.rn53],
				},
				hr,
				{
					label: "Perceptions",
					items: [
						ms.tp1,
						ms.tp2,
						ms.tp3,
						ms.tp4,
						ms.tp5,
						ms.tp6,
						ms.tp7,
						ms.tp8,
						ms.tp9,
						ms.tp10,
						ms.tp11,
						ms.tp12,
						ms.tp13,
						ms.tp14,
						ms.tp15,
					],
				},
				{
					label: "Self",
					items: [
						ms.rn1,
						ms.rn2,
						ms.rn3,
						ms.rn4,
						ms.ph1,
						ms.ph2,
						ms.ph3,
						ms.ph4,
						ms.phq3,
						ms.phq5,
						ms.ahs1,
						ms.ahs3,
						ms.ahs4,
						ms.ahs5,
					],
				},
				{
					label: "Job",
					items: [
						ms.rn8,
						ms.rn10,
						ms.rn11,
						ms.rn12,
						ms.rn14,
						ms.rn15,
						ms.rn16,
						ms.rn17,
						ms.rn18,
						ms.rn19,
						ms.rn21,
						ms.rn22,
						ms.rn24,
						ms.rn26,
					],
				},
				{
					label: "Workplace",
					items: [
						ms.rn27,
						ms.rn28,
						ms.rn29,
						ms.rn30,
						ms.rn31,
						ms.rn111,
						ms.rn32,
						ms.rn34,
						ms.rn35,
						ms.rn36,
						ms.rn37,
						ms.rn39,
						ms.rn40,
						ms.rn42,
						ms.rn43,
						ms.rn44,
					],
				},
			],
		},
		{
			name: "Parent and Caregiver Resilience",
			group: "parent",
			permission: "surveyParent",
			id: "parent-resilience",
			landingPage: [
				reports["snapshot-parent"],
				//reports["executive-summary-parent"],
				reports["comments-parent"],
			],
			pdfMenu: [
				ms["pdf-snapshot"],
				/* {
					label: "Executive Summary",
					items: [
						ms["executive-summary"],
						ms["pdf-cross-sectional"],
						ms["pdf-longitudinal"],
						ms["pdf-time-lag"],
					],
					open: true,
				}, */
			],
			interactiveMenu: [
				{
					label: "Mental Health",
					items: [
						{
							label: "Life Satisfaction <strong>(Cantril*)</strong>",
							items: [ms.cantril4, ms.cantril5, ms.cantril6],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Anxiety & Depression",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
					],
					open: true,
				},
				ms.snapshot,
				{
					label: "Comments",
					items: [ms.rn200, ms.rn201, ms.rn202, ms.rn203, ms.rn204],
				},
				/* hr,
				{
					label: "Child",
					items: [ms.rn60, ms.rn61, ms.rn62, ms.rn81],
				},
				{
					label: "Self",
					items: [ms.rn55, ms.rn56, ms.rn85, ms.ph1, ms.ph2, ms.ph3, ms.ph4, ms.rn57, ms.rn86, ms.rn206],
				},
				{
					label: "School",
					items: [ms.rn87, ms.rn88, ms.rn89, ms.rn54, ms.rn82],
				},
				hr,
				{
					label: "Online Supervision",
					items: [
						{
							label: "Talk with child",
							items: [
								ms.rn63,
								ms.rn65,
								ms.rn66,
								ms.rn67,
								ms.rn68,
								ms.rn69,
								ms.rn70,
								ms.rn83,
								ms.rn84
							],
						},
						ms.rn71,
						ms.rn72,
						ms.rn73,
					],
				}, */
			],
		},
	],
	LearningCurve: [
		{
			name: "Learning Curve Wellbeing",
			group: "student",
			permission: "surveyLearningCurveWellbeing",
			id: "learning-curve-wellbeing",
			landingPage: [
				reports["snapshot-learning-curve-wellbeing"],
				reports["executive-summary-learning-curve-wellbeing"],
				reports["overview-learning-curve"],
				reports["comments"],
			],
			pdfMenu: [
				ms["pdf-snapshot-learning-curve-wellbeing"],
				{
					label: "Executive Summary",
					items: [
						ms["executive-summary-learning-curve-wellbeing"],
						ms["pdf-cross-sectional-learning-curve-wellbeing"],
						ms["pdf-longitudinal-learning-curve-wellbeing"],
						ms["pdf-time-lag-learning-curve-wellbeing"],
					],
					open: true,
				},
				hr,
				{
					key: "pdf-overview-learning-curve",
					label: "Bullying Overview",
				},
			],
			interactiveMenu: [
				{
					label: "Mental Health",
					items: [
						ms["cantril-single"],
						{
							label: "Hope <strong>(CHS)</strong>",
							items: [
								ms.CHS,
								{
									label: "Agency Items",
									items: [ms.chs1, ms.chs3, ms.chs5],
								},
								{
									label: "Pathways Items",
									items: [ms.chs2, ms.chs4, ms.chs6],
								},
							],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Anxiety & Depression",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
						ms["IINS-single"],
					],
					open: true,
				},
				ms["snapshot-learning-curve-wellbeing"],
				ms.comments,
				hr,
				{
					label: "Resilience",
					items: [
						{
							label: "Positive Relationships",
							items: [ms.ry2, ms.ry3, ms.ry4, ms.ry5, ms.ry6, ms.ry7],
						},
						{
							label: "Social Skills",
							items: [ms.ry8, ms.ry9],
						},
						{
							label: "Understanding Self",
							items: [ms.chs1, ms.chs2, ms.chs3],
						},
						{
							label: "Positive Attitude",
							items: [ms.ry21, ms.chs4, ms.chs5, ms.chs6],
						},
						{
							label: "Positive Values",
							items: [ms.tru1, ms.for1, ms.ry23, ms.ry24],
						},
						{
							label: "Positive Contribution",
							items: [ms.ry25],
						},
						{
							label: "Safety",
							items: [ms.ry13, ms.ry14, ms.ry30, ms.ry31],
						},
						{
							label: "Healthy Body",
							items: [ms.sun4, ms.sun3, ms.sun1],
						},
						{
							label: "Healthy Mind",
							items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
						},
						{
							label: "Learning Engagement",
							items: [ms.ry16, ms.ry17, ms.ry18, ms.ry19, ms.ry20, ms.ry28],
						},
					],
				},
				hr,
				{
					label: "Bullying Profile",
					items: [
						{
							label: "Types of Bullying",
							items: [ms.b1, ms.b2, ms.b3, ms.b4],
							open: true,
						},
						ms.b5,
						ms.b6,
						ms.b7,
						ms.b8,
					],
					open: true,
				},
				{
					label: "Student Opinion",
					items: [ms.b11, ms.b12, ms.b13],
				},
				hr,
				{
					label: "Online Behaviours",
					items: [ms.cyb1, ms.cyb4, ms.cyb8, ms.cyb9],
				},
			],
		},
	],
	RN: [
		{
			name: "Workplace Resilience",
			group: "employee",
			permission: "surveyWorkplaceResilience",
			id: "workplace-resilience",
			pdfMenu: [
				ms["pdf-snapshot"],
				ms["pdf-mentalhealth"],
				{
					label: "Executive Summary",
					items: [
						ms["executive-summary"],
						ms["pdf-cross-sectional"],
						ms["pdf-time-lag"],
					],
					open: true,
				},
			],
			interactiveMenu: [
				{
					label: "Mental Health",
					items: [
						{
							label: "Life Satisfaction <strong>(Cantril*)</strong>",
							items: [ms.cantril, ms.cantril2, ms.cantril3],
						},
						{
							label: "Hope <strong>(AHS*)</strong>",
							items: [
								ms.AHS,
								{
									label: "Items",
									items: [ms.ahs1, ms.ahs3, ms.ahs4, ms.ahs5],
								},
							],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Anxiety & Depression",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
					],
					open: true,
				},
				ms.snapshot,
				{
					label: "Comments",
					items: [ms.rn50, ms.rn51, ms.rn110, ms.rn53],
				},
				hr,
				{
					label: "Self",
					items: [
						ms.rn1,
						ms.rn2,
						ms.rn3,
						ms.rn4,
						ms.rn5,
						ms.rn6,
						ms.rn7,
						ms.ph1,
						ms.ph2,
						ms.ph3,
						ms.ph4,
						ms.phq3,
						ms.phq4,
						ms.phq5,
						ms.phq7,
						ms.ahs1,
						ms.ahs3,
						ms.ahs4,
						ms.ahs5,
					],
				},
				{
					label: "Job",
					items: [
						ms.rn8,
						ms.rn9,
						ms.rn80,
						ms.rn11,
						ms.rn12,
						ms.rn13,
						ms.rn90,
						ms.rn91,
						ms.rn92,
						ms.rn17,
						ms.rn18,
						ms.rn19,
						ms.rn93,
						ms.rn94,
						ms.rn22,
						ms.rn23,
						ms.rn95,
						ms.rn25,
						ms.rn96,
					],
				},
				{
					label: "Workplace",
					items: [
						ms.rn97,
						ms.rn98,
						ms.rn99,
						ms.rn30,
						ms.rn100,
						ms.rn32,
						ms.rn33,
						ms.rn34,
						ms.rn101,
						ms.rn36,
						ms.rn102,
						ms.rn38,
						ms.rn103,
						ms.rn104,
						ms.rn105,
						ms.rn106,
						ms.rn107,
						ms.rn108,
						ms.rn109,
					],
				},
			],
		},
	],
	JGI: [
		{
			name: "Nature Wellbeing Lower Primary",
			group: "student",
			permission: "surveyJGI_NatureWellbeingLower",
			id: "JGI_NatureWellbeingLower",
			pdfMenu: [
				ms["pdf-snapshot-jgi-nw-lower"],
				{
					label: "Executive Summary",
					items: [
						ms["executive-summary-jgi-nw-lower"],
						ms["pdf-cross-sectional-jgi-nw-lower"],
						ms["pdf-longitudinal-jgi-nw-lower"],
						ms["pdf-time-lag-jgi-nw-lower"],
					],
					open: true,
				},
			],
			interactiveMenu: [
				ms["snapshot-jgi-nw-lower"],
				hr,
				{
					label: "Resilience",
					items: [
						{
							label: "Healthy Place",
							items: [
								ms.jg28,
								ms.jg29,
								ms.jg30,
								ms.jg31,
								ms.jg32,
								ms.jg33,
								ms.jg39,
								ms.jg40,
							],
						},
						{
							label: "Healthy Mind & Body",
							items: [ms.jg34, ms.jg35, ms.jg36, ms.jg37, ms.jg38],
						},
					],
					open: true,
				},
				{
					label: "Nature",
					items: [
						{
							label: "Nature Engagement",
							items: [
								ms.jg50,
								ms.jg51,
								ms.jg52,
								ms.jg53,
								ms.jg54,
								ms.jg55,
								ms.jg56,
								ms.jg57,
								ms.jg58,
								ms.jg59,
								ms.jg60,
							],
						},
						{
							label: "Nature Advocate",
							items: [
								ms.jg61,
								ms.jg62,
								ms.jg63,
								ms.jg64,
								ms.jg65,
								ms.jg66,
								ms.jg67,
								ms.jg68,
							],
						},
					],
					open: true,
				},
			],
		},
		{
			name: "Nature Wellbeing Upper Primary",
			group: "student",
			permission: "surveyJGI_NatureWellbeingUpper",
			id: "JGI_NatureWellbeingUpper",
			pdfMenu: [
				ms["pdf-snapshot-jgi-nw-upper"],
				{
					label: "Executive Summary",
					items: [
						ms["executive-summary-jgi-nw-upper"],
						ms["pdf-cross-sectional-jgi-nw-upper"],
						ms["pdf-longitudinal-jgi-nw-upper"],
						ms["pdf-time-lag-jgi-nw-upper"],
					],
					open: true,
				},
			],
			interactiveMenu: [
				{
					label: "Mental Health",
					items: [
						ms["cantril-single"],
						{
							label: "Hope <strong>(CHS)</strong>",
							items: [
								ms.CHS,
								{
									label: "Agency Items",
									items: [ms.chs1, ms.chs3, ms.chs5],
								},
								{
									label: "Pathways Items",
									items: [ms.chs2, ms.chs4, ms.chs6],
								},
							],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Anxiety & Depression",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
						ms["IINS-single"],
					],
					open: true,
				},
				ms["snapshot-jgi-nw-upper"],
				{
					label: "Comments",
					items: [ms.jg26, ms.jg27],
				},
				hr,
				{
					label: "Resilience",
					items: [
						{
							label: "Healthy Place",
							items: [
								ms.ry2,
								ms.ry17,
								ms.ry18,
								ms.ry19,
								ms.ry20,
								ms.ry28,
								ms.ry14,
								ms.ry21,
								ms.sun5,
								ms.ry6,
								ms.ry33,
								ms.ry34,
							],
						},
						{
							label: "Healthy Mind",
							items: [
								ms.ph1,
								ms.ph2,
								ms.ph3,
								ms.ph4,
								ms.chs1,
								ms.chs2,
								ms.chs3,
								ms.chs4,
								ms.chs5,
								ms.chs6,
							],
						},
					],
					open: true,
				},
				{
					label: "Nature",
					items: [
						{
							label: "Nature Engagement",
							items: [
								ms.jg1,
								ms.jg2,
								ms.jg3,
								ms.jg4,
								ms.jg5,
								ms.jg6,
								ms.jg7,
								ms.jg8,
								ms.jg9,
								ms.jg10,
								ms.jg11,
								ms.jg12,
								ms.jg13,
								ms.jg14,
								ms.jg15,
								ms.jg16,
							],
						},
						{
							label: "Nature Advocate",
							items: [
								ms.jg17,
								ms.jg18,
								ms.jg19,
								ms.jg20,
								ms.jg21,
								ms.jg22,
								ms.jg23,
								ms.jg24,
								ms.jg25,
							],
						},
					],
					open: true,
				},
			],
		},
		{
			name: "Nature Wellbeing Staff",
			group: "staff",
			permission: "surveyJGI_NatureWellbeingStaff",
			id: "JGI_NatureWellbeingStaff",
			pdfMenu: [
				ms["pdf-snapshot-jgi-nw"],
				{
					label: "Executive Summary",
					items: [
						ms["executive-summary-jgi-nw"],
						ms["pdf-cross-sectional-jgi-nw"],
						ms["pdf-time-lag-jgi-nw"],
					],
					open: true,
				},
			],
			interactiveMenu: [
				ms["snapshot-jgi-nw"],
				hr,
				{
					label: "Students",
					items: [
						ms.jg100,
						{
							label: "Access",
							items: [
								ms.jg101,
								ms.jg102,
								ms.jg103,
								ms.jg104,
								ms.jg105,
								ms.jg106,
								ms.jg107,
							],
						},
					],
				},
				{
					label: "Nature",
					items: [
						ms.jg108,
						ms.jg109,
						ms.jg110,
						ms.jg111,
						ms.jg112,
						ms.jg113,
						ms.jg114,
						ms.jg115,
						ms.jg116,
						ms.jg117,
						ms.jg118,
						ms.jg119,
						ms.jg120,
						ms.jg121,
					],
				},
				hr,
				{
					label: "Feedback",
					items: [
						ms.jg122,
						ms.jg123,
						ms.jg124,
						ms.jg125,
						ms.jg126,
						ms.jg127,
						ms.jg128,
						ms.jg129,
						ms.jg130,
						ms.jg131,
						ms.jg132,
						ms.jg133,
						{
							label: "Comments",
							items: [ms.jg134, ms.jg135, ms.jg136],
						},
					],
				},
			],
		},
	],
	IPE: [
		{
			name: "Mi7",
			group: "student",
			permission: "surveyIPE_Mi7",
			id: "IPE-Mi7",
			landingPage: [
				reports["snapshot-ipe-mi7"],
				reports["executive-summary-ipe-mi7"],
				reports["comments-ipe-mi7"],
			],
			pdfMenu: [
				ms["pdf-snapshot-ipe-mi7"],
				{
					label: "Executive Summary",
					items: [
						ms["executive-summary-ipe-mi7"],
						ms["pdf-cross-sectional-ipe-mi7"],
						ms["pdf-longitudinal-ipe-mi7"],
						ms["pdf-time-lag-ipe-mi7"],
					],
					open: true,
				},
			],
			interactiveMenu: [
				{
					label: "Mental Health",
					items: [
						ms["cantril-single"],
						{
							label: "Hope <strong>(CHS)</strong>",
							items: [
								ms.CHS,
								{
									label: "Agency Items",
									items: [ms.chs1, ms.chs3, ms.chs5],
								},
								{
									label: "Pathways Items",
									items: [ms.chs2, ms.chs4, ms.chs6],
								},
							],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Anxiety & Depression",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
					],
					open: true,
				},
				ms["snapshot-ipe-mi7"],
				{
					label: "Comments",
					items: [ms.ipe43, ms.ipe44, ms.ipe45],
				},
				hr,
				{
					label: "Positive Relationships",
					items: [
						{
							label: "School",
							items: [ms.ipe15, ms.ipe16],
							open: true,
						},
						{
							label: "Family",
							items: [ms.ipe17, ms.ipe18],
							open: true,
						},
						{
							label: "Social-Emotional Awareness",
							items: [ms.ipe19, ms.ipe20],
							open: true,
						},
						{
							label: "School Climate / Belonging",
							items: [ms.ipe21, ms.ipe22],
							open: true,
						},
						{
							label: "Other Adults",
							items: [ms.ipe23],
							open: true,
						},
						{
							label: "Diversity & Inclusion",
							items: [ms.ipe24],
							open: true,
						},
						{
							label: "Equity & Fairness",
							items: [ms.ipe25],
							open: true,
						},
						{
							label: "Bullying",
							items: [ms.ipe72, ms.ipe73],
							open: true,
						},
					],
				},
				{
					label: "Positive Emotions",
					items: [
						{
							label: "Happiness",
							items: [ms.ipe2, ms.ipe3],
							open: true,
						},
						{
							label: "Optimism",
							items: [ms.ipe4],
							open: true,
						},
						{
							label: "Hope",
							items: [ms.chs1, ms.chs2, ms.chs3],
							open: true,
						},
					],
				},
				{
					label: "Positive Health",
					items: [
						{
							label: "Anxiety",
							items: [ms.ph1, ms.ph2],
							open: true,
						},
						{
							label: "Depression",
							items: [ms.ph3, ms.ph4],
							open: true,
						},
						{
							label: "Diet",
							items: [ms.ipe39, ms.ipe40],
							open: true,
						},
						{
							label: "Exercise",
							items: [ms.ipe41],
							open: true,
						},
						{
							label: "Sleep",
							items: [ms.ipe42],
							open: true,
						},
					],
				},
				{
					label: "Positive Engagement",
					items: [
						{
							label: "Absorption",
							items: [ms.ipe9],
							open: true,
						},
						{
							label: "Learning Engagement",
							items: [ms.ipe10, ms.ipe11],
							open: true,
						},
						{
							label: "Academic Self Concept",
							items: [ms.ipe12, ms.ipe13],
							open: true,
						},
						{
							label: "Academic Buoyancy",
							items: [ms.ipe14],
							open: true,
						},
					],
				},
				{
					label: "Positive Accomplishment",
					items: [
						{
							label: "Mindset",
							items: [ms.ipe5],
							open: true,
						},
						{
							label: "Decision-making",
							items: [ms.ipe6],
							open: true,
						},
						{
							label: "Perseverance",
							items: [ms.ipe7, ms.ipe8, ms.ipe36],
							open: true,
						},
						{
							label: "Hope",
							items: [ms.chs4, ms.chs5, ms.chs6],
							open: true,
						},
						{
							permission: "IPEmoduleAdaptability",
							label: "Adaptability",
							items: [ms.ipe67, ms.ipe68, ms.ipe69, ms.ipe70, ms.ipe71],
							open: true,
						},
					],
				},
				{
					label: "Positive Purpose",
					items: [
						{
							label: "Contribution",
							items: [ms.ipe26, ms.ipe27, ms.ipe37, ms.ipe38],
							open: true,
						},
						{
							label: "Worth",
							items: [ms.ipe28, ms.ipe29],
							open: true,
						},
						{
							label: "Direction",
							items: [ms.ipe30],
							open: true,
						},
					],
				},
				{
					label: "Character Strengths",
					items: [
						{
							label: "Self-knowledge",
							items: [ms.ipe31, ms.ipe32],
							open: true,
						},
						{
							label: "Self-regulation",
							items: [ms.ipe33, ms.ipe34],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							negatePermission: true,
							label: "Empathy",
							items: [ms.ipe35],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Empathy",
							items: [ms.ipe35, ms.ipe54],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Compassion",
							items: [ms.ipe55],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Gratitude",
							items: [ms.ipe56],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Teamwork",
							items: [ms.ipe57],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Creativity",
							items: [ms.ipe58],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Forgiveness",
							items: [ms.ipe59, ms.ipe60],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Trust",
							items: [ms.ipe61, ms.ipe66],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Fairness",
							items: [ms.ipe62],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Courage",
							items: [ms.ipe63],
							open: true,
						},
						{
							permission: "IPEmoduleCharacterStrengths",
							label: "Mindfulness",
							items: [ms.ipe64, ms.ipe65],
							open: true,
						},
					],
				},
				hr,
				{
					permission: "IPEmoduleBoardingHouse",
					label: "Boarding House",
					items: [
						ms.ipe46,
						ms.ipe47,
						ms.ipe48,
						ms.ipe49,
						ms.ipe50,
						ms.ipe51,
						ms.ipe52,
						ms.ipe53,
					],
				},
				{
					permission: "IPEmoduleRiskyBehaviours",
					label: "Risky Behaviours",
					items: [
						ms.aud1,
						ms.aud2,
						ms.aud3,
						ms.gam1,
						ms.gam2,
						ms.ipe74,
						ms.ipe75,
					],
				},
			],
		},
	],
	KS: [
		{
			name: "Kindness",
			group: "student",
			permission: "surveyKindSchools",
			id: "kind-schools",
			pdfMenu: [ms["pdf-snapshot-ks"]],
			interactiveMenu: [
				ms["snapshot-ks"],
				{
					label: "Comments",
					items: [ms.k13, ms.k14, ms.k15, ms.k16, ms.k17],
				},
				hr,
				{
					label: "Compassion",
					items: [
						{
							label: "Calm",
							items: [ms.k1, ms.ry14],
						},
						{
							label: "Empathetic",
							items: [ms.ry21, ms.ry26],
						},
						{
							label: "Grateful",
							items: [ms.k2, ms.k3],
						},
					],
				},
				{
					label: "Connection",
					items: [
						{
							label: "Engaged",
							items: [ms.k19, ms.k4],
						},
						{
							label: "Caring",
							items: [ms.k5, ms.k6],
						},
						{
							label: "Inclusive",
							items: [ms.k7, ms.ry10],
						},
					],
				},
				{
					label: "Courage",
					items: [
						{
							label: "Aware",
							items: [ms.k8, ms.k9],
						},
						{
							label: "Brave",
							items: [ms.k21, ms.k10],
						},
						{
							label: "Trustworthy",
							items: [ms.k11, ms.k12],
						},
					],
				},
				{
					label: "Mental Health",
					items: [
						ms["GAD-2"],
						{
							label: "Anxiety (GAD-2)",
							items: [ms.ph1, ms.ph2],
						},
					],
				},
			],
		},
	],
	PF: [
		{
			name: "TOMS",
			group: "student",
			permission: "surveyPhillipsFoundation",
			id: "phillips-foundation",
			landingPage: [
				reports["annual-outcomes"],
				reports["comments"]
			],
			pdfMenu: [ms["pdf-annualoutcomes"]],
			interactiveMenu: [
				{
					label: "TOMS",
					items: [
						ms["cantril-single"],
						{
							label: "Hope <strong>(CHS)</strong>",
							items: [
								ms.CHS,
								{
									label: "Agency Items",
									items: [ms.chs1, ms.chs3, ms.chs5],
								},
								{
									label: "Pathways Items",
									items: [ms.chs2, ms.chs4, ms.chs6],
								},
							],
						},
						{
							label: "Anxiety <strong>(GAD-2)</strong>",
							items: [
								ms["GAD-2"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2],
								},
							],
						},
						{
							label: "Depression <strong>(PHQ-2)</strong>",
							items: [
								ms["PHQ-2"],
								{
									label: "Items",
									items: [ms.ph3, ms.ph4],
								},
							],
						},
						{
							label: "Coping <strong>(CSI-4) [adapted]</strong>",
							items: [
								ms["CSI-4"],
								{
									label: "Items",
									items: [ms.cop1, ms.cop2, ms.cop3, ms.cop4],
								},
							],
						},
						{
							label: "Resilience Score",
							items: [
								ms["resilience"],
								{
									label: "Items",
									items: [
										{
											key: 'cantril',
											label: 'Cantril'
										},
										{
											key: 'CHS',
											label: 'CHS'
										},
									],
								},
							],
						},
						{
							label: "Anxiety & Depression",
							items: [
								ms["healthy-mind"],
								{
									label: "Items",
									items: [ms.ph1, ms.ph2, ms.ph3, ms.ph4],
								},
							],
						},
						ms["IINS-single"]
					],
					open: true,
				},
				ms["comments"]
			],
		}
	],
	LR: [
		{
			name: "Audit Tool (SWAT)",
			group: "swat",
			permission: "surveySWAT",
			id: "school-wellbeing-audit-tool",
			landingPage: [
				reports["swat"]
			],
			pdfMenu: [ms["pdf-swat"]],
			interactiveMenu: [
				{
					label: "SWAT",
					items: [
						ms.swat1,
						ms.swat2,
						ms.swat3,
						ms.swat4,
						ms.swat5,
						ms.swat6,
						ms.swat7,
						ms.swat8,
						ms.swat9,
						ms.swat10,
						ms.swat11,
						ms.swat12,
						ms.swat13,
						ms.swat14,
						ms.swat15
					],
					open: true,
				}
			],
		},
	]
};

function surveys(theme) {
	switch (theme.name) {
		case "Resilient Youth":
			return surveySpecs.RY;
			break;
		case "Resilient Nation":
		case "Lead-Well":
			return surveySpecs.RN;
			break;
		case "Learning Curve":
			return surveySpecs.LearningCurve;
			break;
		case "Jane Goodall Institute":
			return surveySpecs.JGI;
			break;
		case "Institute of Positive Education":
			return surveySpecs.IPE;
			break;
		case "Kind Schools":
			return surveySpecs.KS;
			break;
		case "The Phillips Foundation":
		case "Social Enterprise Academy":
		case "TOMS":
			return surveySpecs.PF;
			break;
		case "Living Ripples":
			return surveySpecs.RY.concat(surveySpecs.LR);
			break;
		default:
			return [];
			break;
	}
}

export default surveys;
